import { useEffect, useState } from 'react';
import './App.css';
import Home from './components/home/Home';
import { MqttClient } from "mqtt";
import { ReceiptIssueStateUpdatedMessage, SubmittedPrintMessage } from './models/receipt/SubmittedPrintMessage';
import PrintReceipt from './components/receipt/PrintReceipt';
import { Button, Form, Modal } from 'react-bootstrap';
import MqttHelper from './helpers/MqttHelper';
import { BusMessages } from './constants/BusMessages';
import './styles/ButtonStyles.css';
import ReceiptIssuedScreen from './components/home/ReceiptIssuedScreen';
import { useLocalStorage } from '@uidotdev/usehooks';

function App() {
  const [cashboxid, setCashboxId] = useLocalStorage("cashboxid", "");
  const [accesstoken, setAccesstoken] = useLocalStorage("accesstoken", "");
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [submitedPrintMessage, setSubmitedPrintMessage] = useState<SubmittedPrintMessage>();
  const [showIssuedMessage, setShowIssuedMessage] = useState<boolean>(false);
  const [receiptIssueStateUpdatedMessage, setReceiptIssueStateUpdatedMessage] = useState<ReceiptIssueStateUpdatedMessage | null>(null);
  const [isConnectedModalOpen, setIsConnectedModalOpen] = useState<boolean>(false);
  let client: MqttClient;

  useEffect(() => {
    if (cashboxid && accesstoken) {
      onCashboxChange(cashboxid, accesstoken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showIssuedMessage) {
      const timer = setTimeout(() => {
        setShowIssuedMessage(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showIssuedMessage]);


  return (
    <div className="App">
      {
        showReceipt ? <PrintReceipt onCloseReceipt={onCloseReceipt} submitedPrintMessage={submitedPrintMessage} /> : <></>
      }
      {
        showIssuedMessage && receiptIssueStateUpdatedMessage ? <ReceiptIssuedScreen ReceiptIssueStateUpdatedMessage={receiptIssueStateUpdatedMessage} ></ReceiptIssuedScreen> : <></>
      }
      {
        !showReceipt && !showIssuedMessage && <Home onCashboxChange={onCashboxChange} />
      }
      <Modal show={isConnectedModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Connected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Connected to global bus (CashBoxID: {cashboxid})</Form.Label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-primary' onClick={() => setIsConnectedModalOpen(false)}> OK</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  function onCloseReceipt() {
    setShowReceipt(false);
  }

  function onCashboxChange(newCashboxId: string, newAccessToken: string) {
    MqttHelper.ConnectAndSubscribe(client, cashboxid, newCashboxId, newAccessToken, onConnected, onMessageReceived);

    setCashboxId(newCashboxId);
    setAccesstoken(newAccessToken);
  }

  function onConnected() {
    setIsConnectedModalOpen(true);
  }

  function onMessageReceived(topic: string, message: string) {
    console.log("Received", message);
    if (topic.includes(BusMessages.SubmittedForPrinting)) {
      setSubmitedPrintMessage(JSON.parse(message));
      setShowReceipt(true);
    }
    if (topic.includes(BusMessages.Printed)) {
      console.log("Printed");
      setReceiptIssueStateUpdatedMessage(JSON.parse(message));
      setShowReceipt(false);
      setShowIssuedMessage(true);
    }
  }
}

export default App;
