import mqtt, { IClientOptions, MqttClient } from "mqtt";
import { v4 as uuidv4 } from 'uuid';

export default class MqttHelper {
    static ConnectAndSubscribe(client: MqttClient,
        cashboxId: string,
        newCashboxId: string,
        accessToken: string,
        onConnected: () => void,
        onMessageReceived: (topic: string, message: string) => void) {
        const gatewayUrl = "wss://gateway-sandbox.fiskaltrust.eu:443/mqtt";
        if (client?.connected) {
            if (cashboxId) {
                client.unsubscribe(`${newCashboxId}/#`);
            }
            client.end();
        }
        const transformWsUrl = (url: string, options: IClientOptions, client: MqttClient) => {
            client.options.clientId = `${newCashboxId}receiptdisplay${uuidv4()}`;
            client.options.username = newCashboxId;
            client.options.password = accessToken;
            return gatewayUrl;
        }

        client = mqtt.connect({ transformWsUrl: transformWsUrl });
        client.on("connect", () => {
            if (newCashboxId) {
                client.subscribe(`${newCashboxId}/#`, (err) => {
                    if (!err) {
                        onConnected();
                    }
                });
            }
        });

        client.on("message", (topic, message) => {
            var decodedMessage = new TextDecoder("utf-8").decode(message)
            onMessageReceived(topic, decodedMessage);
        });

        client.on("error", (error) => {
            alert(error);
        });
    }
}